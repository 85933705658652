/* eslint-disable react/jsx-props-no-spreading */
import { styled } from "@mui/material/styles";
import React, { useCallback } from "react";

import Dropzone from "react-dropzone";

import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Box, IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import DriveFolderUploadRoundedIcon from "@mui/icons-material/DriveFolderUploadRounded";
import EditIcon from "@mui/icons-material/Edit";

import { useDownloadFile, useDownloadFileV2, useToggle, useUploadFile } from "hooks";

import { downloadFile, downloadFileV2, textOrUnknown } from "utils";

import { File } from "types/global";

import { Text } from "components/core";
import ProductImage from "../ProductImage";
import { TextProps } from "../Text";

const Input = styled("input")({
  display: "none",
});

const FileListContainer = styled(Box)({
  width: "auto",
  // marginTop: "8px",
  // paddingTop: 5,
  // paddingBottom: 5,
});

const FileItemContainer = styled(Box, {
  shouldForwardProp: (props) => props !== "readOnly" && props !== "removeButtonDisabled",
})<{
  readOnly: boolean;
  removeButtonDisabled: boolean;
}>(({ theme, readOnly, removeButtonDisabled }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: !removeButtonDisabled && readOnly ? "space-between" : "unset",
  width: !removeButtonDisabled && readOnly ? "100%" : "unset",
}));

const FileNameWrapper = styled(Box, {
  shouldForwardProp: (props) =>
    props !== "readOnly" && props !== "removeButtonDisabled" && props !== "index",
})<{
  readOnly: boolean;
  removeButtonDisabled: boolean;
  index: number;
}>(({ theme, readOnly, removeButtonDisabled, index }) => ({
  paddingTop: !removeButtonDisabled && readOnly && index !== 0 ? 8 : 0,
  paddingRight: !removeButtonDisabled && readOnly && index !== 0 ? 20 : 0,
}));

const FileIconButton = styled(IconButton)(({ theme }) => ({
  cursor: "pointer",
  marginLeft: "8px",
}));

const CusRemoveIcon = styled(DeleteIcon)(({ theme }) => ({}));

export interface UploadFileProps extends LoadingButtonProps {
  fullWidth?: LoadingButtonProps["fullWidth"];
  variant?: LoadingButtonProps["variant"];
  label?: string;
  previewOrder?: boolean;
  singleFile?: boolean;
  loading?: boolean;
  disabled?: boolean;
  reuploadAction?: boolean;
  canDownload?: boolean;
  editActions?: boolean;
  removeButtonDisabled?: boolean;
  removeEnabled?: boolean;
  readOnly?: boolean;
  imagePreview?: boolean;
  direction?: "row" | "column";
  textProps?: Omit<TextProps, "children">;
  // todo: file api reponse issue
  files?: any[];
  onUpload?: (files: any) => void;
  onRemove?: (id: File["id"]) => void;
}

export default function UploadFile({
  fullWidth = false,
  variant = "outlined",
  label = "Upload",
  previewOrder = false,
  singleFile = false,
  reuploadAction = false,
  loading = false,
  disabled = false,
  removeButtonDisabled = false,
  canDownload = true,
  removeEnabled = false,
  editActions = false,
  readOnly = false,
  imagePreview = false,
  direction = "row",
  textProps,
  files = [],
  onUpload,
  onRemove,
  ...props
}: UploadFileProps) {
  const [isEdit, toggleEdit] = useToggle();

  const { isLoading: isUploadFileLoading, refetch: fetchUploadFile } = useUploadFile();

  const { isLoading: isDownloadFileLoading, refetch: fetchDownloadFile } = useDownloadFileV2();

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      const newFiles: File[] = [];

      const uploadFileFunctionArray: any[] = [];

      const uploadFile = async (file: any) => {
        const formData = new FormData();
        formData.append("file", file);

        const res = await fetchUploadFile(formData);

        return res?.data?.data;
      };

      for (let i = 0; i < acceptedFiles.length; i++) {
        uploadFileFunctionArray.push(uploadFile(acceptedFiles[i]));

        newFiles.push({
          id: null,
          fileName: acceptedFiles[i]?.name,
        });
      }

      Promise.all(uploadFileFunctionArray).then((result) => {
        if (uploadFileFunctionArray?.length) {
          for (let i = 0; i < result.length; i++) {
            newFiles[i].id = result[i]?.id;
            newFiles[i].location = result[i]?.location;
            newFiles[i].fileType = result[i]?.fileType;
            newFiles[i].size = result[i]?.size;
          }

          if (onUpload) onUpload(newFiles);
        }
      });
    },
    [onUpload],
  );

  const handleClickFile = (item: any) => {
    if (!item?.id) return;

    fetchDownloadFile(item?.location).then(({ data }) => {
      if (data) downloadFileV2(data, item?.fileName);
    });
  };

  return (
    <Box
      sx={{
        width: "auto",
        display: "flex",
        flex: "0 0 auto",
        flexFlow: previewOrder || direction === "column" ? "column" : "row",
      }}
    >
      {((editActions && !files.length) ||
        (editActions && isEdit && !readOnly) ||
        (!editActions && !readOnly)) && (
        <Box
          sx={{
            display: "flex",
            order: previewOrder ? 1 : "unset",
            pointerEvents: disabled ? "none" : "initial",
          }}
        >
          <Dropzone onDrop={onDrop} multiple={!singleFile}>
            {({ getRootProps, getInputProps }) => (
              <Box {...getRootProps()}>
                <Input {...getInputProps()} />

                <LoadingButton
                  {...props}
                  fullWidth={fullWidth}
                  variant={variant}
                  disabled={disabled}
                  loading={isUploadFileLoading || loading}
                  component="span"
                >
                  {label}
                </LoadingButton>
              </Box>
            )}
          </Dropzone>
        </Box>
      )}

      {files?.length ? (
        <FileListContainer
          sx={{
            display: "flex",
            order: previewOrder ? 0 : "unset",
            flexFlow:
              previewOrder || direction === "row"
                ? "row"
                : direction === "column"
                ? direction
                : "unset",
            marginLeft: direction === "column" ? 0 : "8px",
            marginTop: direction === "column" ? "8px" : 0,
          }}
        >
          {files?.map((file, index) =>
            imagePreview ? (
              <Box
                sx={{ width: "auto", position: "relative", display: "inline-block" }}
                key={file?.id}
              >
                {!readOnly && (
                  <IconButton
                    sx={{ position: "absolute", left: "80px", top: "-4px" }}
                    color="primary"
                    disabled={isUploadFileLoading || loading}
                    onClick={() => {
                      if (onRemove) onRemove(file?.id);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}

                <ProductImage
                  onClick={() => canDownload && handleClickFile(file)}
                  location={file?.location || ""}
                  width={100}
                  height={100}
                />
              </Box>
            ) : (
              <FileItemContainer
                key={file?.id || index}
                readOnly={readOnly}
                removeButtonDisabled={removeButtonDisabled}
              >
                {file && (
                  <FileNameWrapper
                    index={index}
                    readOnly={readOnly}
                    removeButtonDisabled={removeButtonDisabled}
                  >
                    <Text
                      {...textProps}
                      sx={{
                        cursor: canDownload ? "pointer" : "inherit",
                        wordBreak: "break-all",
                        textDecoration: canDownload ? "underline" : "none",
                      }}
                      onClick={() => canDownload && handleClickFile(file)}
                    >
                      {textOrUnknown(file?.fileName)}
                    </Text>
                  </FileNameWrapper>
                )}

                {reuploadAction && (
                  <Dropzone onDrop={onDrop} multiple={!singleFile}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <Input {...getInputProps()} />

                        <IconButton
                          disabled={isUploadFileLoading}
                          sx={{ marginLeft: "8px" }}
                          color="primary"
                          onClick={() => toggleEdit()}
                        >
                          <DriveFolderUploadRoundedIcon />
                        </IconButton>
                      </div>
                    )}
                  </Dropzone>
                )}

                {editActions && !isEdit && (
                  <IconButton
                    sx={{ marginLeft: "8px" }}
                    color="primary"
                    onClick={() => toggleEdit()}
                    disabled={loading}
                  >
                    <EditIcon />
                  </IconButton>
                )}

                {(removeEnabled ||
                  (editActions && isEdit && !readOnly && !removeButtonDisabled) ||
                  (!editActions && isEdit && !removeButtonDisabled) ||
                  (!editActions && isEdit && !removeButtonDisabled && !readOnly)) && (
                  <FileIconButton
                    size="small"
                    onClick={() => onRemove && onRemove(file?.id)}
                    disabled={isDownloadFileLoading}
                  >
                    <CusRemoveIcon fontSize="small" color="primary" />
                  </FileIconButton>
                )}

                {editActions && isEdit && (
                  <>
                    <FileIconButton color="primary" size="small" onClick={() => toggleEdit()}>
                      <CloseIcon />
                    </FileIconButton>

                    <FileIconButton color="primary" size="small" onClick={() => toggleEdit()}>
                      <DoneIcon />
                    </FileIconButton>
                  </>
                )}
              </FileItemContainer>
            ),
          )}
        </FileListContainer>
      ) : (
        readOnly && <Text color="black">No file found</Text>
      )}
    </Box>
  );
}
