/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
import React from "react";

import { Box, styled } from "@mui/material";
import { BasicChip, BasicChipProps } from "components/core";

import { NOT_APPLICABLE } from "constants/global";
import { TASK_STATUS } from "constants/item";
import { TaskStatus } from "types/task";

declare module "@mui/material/Chip" {
  export interface ChipPropsColorOverrides {
    violet: true;
    orange: true;
  }
}

enum StatusDotSize {
  SemiSmall = "4px",
  Small = "10px",
  SemiMedium = "5px",
  Medium = "12px",
  SemiLarge = "6px",
  Large = "14px",
}

enum StatusInnerDotSize {
  SemiSmall = "4px",
  Small = "6px",
  SemiMedium = "5px",
  Medium = "8px",
  SemiLarge = "6px",
  Large = "10px",
}

enum StatusDotTriangleSize {
  SemiExtraSmall = "4px",
  ExtraSmall = "8px",
  SemiSmall = "6px",
  Small = "10px",
  SemiMedium = "7.5px",
  Medium = "15px",
  SemiLarge = "8px",
  Large = "18px",
}

const StyledDot = styled(Box, {
  shouldForwardProp: (props) =>
    props !== "triangle" && props !== "status" && props !== "size" && props !== "dotReversed",
})<{
  triangle: boolean;
  status: TaskStatus | null;
  size: BasicChipProps["fontSize"];
  dotReversed: boolean;
}>(({ theme, status, size, dotReversed, triangle }) => {
  const getTriangleDotSize = (isBorderX = false) => {
    let dotSize = "unset";

    switch (size) {
      case "info":
        dotSize = isBorderX
          ? StatusDotTriangleSize.SemiExtraSmall
          : StatusDotTriangleSize.SemiSmall;
        break;

      case "title":
        dotSize = isBorderX ? StatusDotTriangleSize.SemiMedium : StatusDotTriangleSize.SemiLarge;
        break;

      default:
        dotSize = isBorderX ? StatusDotTriangleSize.SemiSmall : StatusDotTriangleSize.SemiMedium;
        break;
    }

    return dotSize;
  };

  const getDotSize = () => {
    let dotSize = "unset";

    switch (size) {
      case "info":
        dotSize = StatusDotSize.Small;
        break;

      case "title":
        dotSize = StatusDotSize.Large;
        break;

      default:
        dotSize = StatusDotSize.Medium;
        break;
    }

    return dotSize;
  };

  const getDotInnerCircleSize = () => {
    let dotSize = "unset";

    switch (size) {
      case "info":
        dotSize = StatusInnerDotSize.Small;
        break;

      case "title":
        dotSize = StatusInnerDotSize.Large;
        break;

      default:
        dotSize = StatusInnerDotSize.Medium;
        break;
    }

    return dotSize;
  };

  const getDotColor = () => {
    let dotColor = "unset";

    if (!status) {
      if (triangle) {
        dotColor = theme.palette.grey[400];
      } else {
        dotColor = theme.palette.grey[100];
      }

      return dotColor;
    }

    switch (status) {
      case TaskStatus.Cancelled:
        dotColor = theme.palette.grey[100];
        break;
      case TaskStatus.NoGrouping:
      case TaskStatus.EmptyTask:
      case TaskStatus.NotStarted:
      case TaskStatus.NotOpenForQuote:
      case TaskStatus.NotOpenForQuoteRetest:
      case TaskStatus.Rejected:
      case TaskStatus.CapPendingApproval:
        dotColor = theme.palette.grey[400];
        break;
      case TaskStatus.WaitingForLabQuote:
      case TaskStatus.QuoteAvailable:
      case TaskStatus.QuoteConfirmed:
        dotColor = "#0288d1";
        break;
      case TaskStatus.InProgress:
      case TaskStatus.ReportUpdated:
      case TaskStatus.SampleReceived:
      case TaskStatus.SampleSent:
      case TaskStatus.Pending:
      case TaskStatus.AuditInProgress:
        dotColor = "#FFD12E";
        break;
      case TaskStatus.CompletedPending:
        dotColor = "#521B93";
        break;
      case TaskStatus.Completed:
        dotColor = "orange";
        break;
      case TaskStatus.ConditionalApproved:
      case TaskStatus.Approved:
      case TaskStatus.CapApproved:
      case TaskStatus.CapAccepted:
        dotColor = "#0DA16C";
        break;

      default:
        dotColor = theme.palette.error.main;
        break;
    }

    return dotColor;
  };

  return {
    display: "inline-flex",
    order: dotReversed ? 1 : 2,
    flex: "0 0 auto",
    borderLeft: triangle ? `${getTriangleDotSize(true)} solid transparent` : "unset",
    borderRight: triangle ? `${getTriangleDotSize(true)} solid transparent` : "unset",
    borderBottom: triangle ? `${getTriangleDotSize()} solid ${getDotColor()}` : "unset",
    border: !triangle && status === "completed_pending" ? "solid #521B93 1px" : "",
    width: getDotSize(),
    height: getDotSize(),
    borderRadius: triangle ? "unset" : "100%",
    position: status === "completed_pending" ? "relative" : "unset",
    backgroundColor: triangle ? "unset" : status === "completed_pending" ? "unset" : getDotColor(),

    boxSizing: "border-box",
    "&::before":
      status === "completed_pending" && !triangle
        ? {
            content: "' '",
            position: "absolute",
            width: getDotInnerCircleSize(),
            height: getDotInnerCircleSize(),
            borderRadius: triangle ? "unset" : "100%",
            border: "solid #521B93 1px",
            boxSizing: "border-box",
            top: "1px",
            left: "1px",
          }
        : "",
  };
});

export interface TaskStatusChipProps extends BasicChipProps {
  triangle?: boolean;
  dot?: boolean;
  dotReversed?: boolean;
  labelDisabled?: boolean;
  labelColorDisabled?: boolean;
  labelPaddingDisabled?: boolean;
  hasGrouping?: boolean;
  status: TaskStatus | null;
}

export default function TaskStatusChip({
  fontSize = "text",
  variant = "outlined",
  triangle = false,
  dot = false,
  dotReversed = false,
  status,
  labelDisabled = false,
  labelColorDisabled = false,
  labelPaddingDisabled = false,
  ...rest
}: TaskStatusChipProps) {
  const handleStatusConfig = (
    taskStatus: TaskStatus | null,
  ): {
    label: (typeof TASK_STATUS)[TaskStatus] | "";
    color: BasicChipProps["color"];
  } => {
    const config: {
      label: (typeof TASK_STATUS)[TaskStatus] | "";
      color: BasicChipProps["color"];
    } = {
      label: "",
      color: "default",
    };

    switch (taskStatus) {
      case TaskStatus.NoGrouping:
        config.label = TASK_STATUS.no_grouping;
        config.color = "secondary";
        break;
      case TaskStatus.EmptyTask:
        config.label = TASK_STATUS.empty_task;
        config.color = "secondary";
        break;
      case TaskStatus.NotStarted:
        config.label = TASK_STATUS.not_started;
        config.color = "secondary";
        break;
      case TaskStatus.Rejected:
        config.label = TASK_STATUS.rejected;
        config.color = "secondary";
        break;
      case TaskStatus.CapPendingApproval:
        config.label = TASK_STATUS.cap_pending_approval;
        config.color = "secondary";
        break;

      case TaskStatus.NotOpenForQuote:
        config.label = TASK_STATUS.not_open_for_quote;
        config.color = "info";
        break;
      case TaskStatus.NotOpenForQuoteRetest:
        config.label = TASK_STATUS.not_open_for_quote_retest;
        config.color = "info";
        break;
      case TaskStatus.QuoteConfirmed:
        config.label = TASK_STATUS.quote_confirmed;
        config.color = "info";
        break;
      case TaskStatus.WaitingForLabQuote:
        config.label = TASK_STATUS.waiting_for_lab_quote;
        config.color = "info";
        break;
      case TaskStatus.QuoteAvailable:
        config.label = TASK_STATUS.quote_available;
        config.color = "info";
        break;

      // todo: task in progress
      case TaskStatus.AuditInProgress:
        config.label = TASK_STATUS.audit_in_progress;
        config.color = "warning";
        break;
      case TaskStatus.InProgress:
        config.label = TASK_STATUS.in_progress;
        config.color = "warning";
        break;
      case TaskStatus.Pending:
        config.label = TASK_STATUS.pending;
        config.color = "warning";
        break;
      case TaskStatus.SampleReceived:
        config.label = TASK_STATUS.sample_received;
        config.color = "warning";
        break;
      case TaskStatus.SampleSent:
        config.label = TASK_STATUS.sample_sent;
        config.color = "warning";
        break;
      case TaskStatus.ReportUpdated:
        config.label = TASK_STATUS.in_progress_result_report_updated;
        config.color = "warning";
        break;

      // todo: task error
      case TaskStatus.Fail:
        config.label = TASK_STATUS.fail;
        config.color = "error";
        break;
      case TaskStatus.Cancelled:
        config.label = TASK_STATUS.cancelled;
        config.color = "error";
        break;
      case TaskStatus.CapRejected:
        config.label = TASK_STATUS.cap_rejected;
        config.color = "error";
        break;
      case TaskStatus.RejectedRequestCap:
        config.label = TASK_STATUS.rejected_request_cap;
        config.color = "error";
        break;
      case TaskStatus.RejectedUpdateReport:
        config.label = TASK_STATUS.rejected_update_report;
        config.color = "error";
        break;

      // todo: task done
      case TaskStatus.CompletedPending:
        config.label = TASK_STATUS.completed_pending;
        config.color = "violet";
        break;
      case TaskStatus.Completed:
        config.label = TASK_STATUS.completed;
        config.color = "orange";
        break;
      case TaskStatus.CapApproved:
        config.label = TASK_STATUS.cap_approved;
        config.color = "success";
        break;
      case TaskStatus.CapAccepted:
        config.label = TASK_STATUS.cap_accepted;
        config.color = "success";
        break;
      case TaskStatus.ConditionalApproved:
        config.label = TASK_STATUS.conditional_approved;
        config.color = "success";
        break;
      case TaskStatus.Approved:
        config.label = TASK_STATUS.approved;
        config.color = "success";
        break;

      default:
        config.label = TASK_STATUS.not_open_for_quote;
        config.color = "secondary";
        break;
    }

    return config;
  };

  const { label, color } = handleStatusConfig(status);

  const chipColor = labelColorDisabled ? "default" : color;

  return (
    <Box
      sx={{
        display: labelDisabled ? "inline-block" : "inline-flex",
        alignItems: "center",
        wordBreak: "break-all",
      }}
    >
      {!labelDisabled && (
        <BasicChip
          sx={{
            ".MuiChip-label": {
              whiteSpace: "initial",
              padding: labelPaddingDisabled ? "0 8px !important" : "4px 8px",
            },
            order: dot && dotReversed ? 2 : 1,
          }}
          {...rest}
          fontSize={fontSize}
          variant={variant}
          color={chipColor}
          label={label}
        />
      )}

      {dot && (
        <StyledDot triangle={triangle} dotReversed={dotReversed} size={fontSize} status={status} />
      )}
    </Box>
  );
}
