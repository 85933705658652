/* eslint-disable no-nested-ternary */
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Virtuoso } from "react-virtuoso";

import {
  Box,
  Checkbox,
  IconButton,
  ListItemText,
  MenuItem,
  Popover,
  TextField,
  Theme,
} from "@mui/material";

// import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";

import { BasicButton } from "components/core/Button";
import { BasicSelectOption } from "components/core/Input";
import { BasicTooltip } from "components/core/Tooltip";
import { CHANGE_REQUEST_STATUS } from "constants/change-request";
import {
  BOM_TYPE_OPTIONS,
  CONCEPT_OPTIONS,
  COUNTRY_CODE_OPTIONS,
  DC_CODE_OPTIONS,
  SKU_STATUS_OPTIONS,
  TASK_STATUS,
  ACTION_CHANGE_LOG_OPTIONS,
  CHANGE_TYPE_CHANGE_LOG_OPTIONS,
} from "constants/item";

import { DEPT_KEY, VENDOR_KEY } from "constants/task";
import { useDebounce } from "hooks";
import { CustomHeader } from "types/task";
import { textOrUnknown } from "utils";
import {
  getArrayDisplayValue,
  getArrayIndexOfValue,
  getCurrentHeaderOptions,
  getDisplayValue,
  getObjectDisplayValue,
} from "utils/table";

import { TableListType } from ".";

const FilterIconWrapper = styled(Box, {
  shouldForwardProp: (props) => props !== "active",
})<{
  active: boolean;
}>(({ theme, active }) => ({
  svg: {
    color: active ? theme.palette.primary.main : "#808080",
    width: 23,
    height: 23,
  },
}));

const WrapperBox = styled(Box)({
  padding: "8px",
});

const CustomPopover = styled(Popover)({
  ".MuiPaper-root": {
    width: "320px",
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  taskFilter: {
    "& .multiselect-container": {
      height: 400,
    },
    "& .optionContainer": {
      height: 355,
    },
  },
  filter: {
    color: theme.palette.primary.light,
    width: 23,
    height: 23,
  },
  selected: {
    color: theme.palette.primary.main,
  },
}));

export const EXPORT_MARKET_ACCESSOR = "exportMarket";
export const COUNTRY_CODE_LIST_ACCESSOR = "countryCodeList";
export const DC_LIST_ACCESSOR = "dcList";
export const BOM_ACCESSOR = "bom";
export const SKU_STATUS_ACCESSOR = "skuStatus";
export const STATUS_ACCESSOR = "status";
export const CONCEPT_ACCESSOR = "concept";
export const DEPT_ACCESSOR = "deptNum";
export const VENDOR_ACCESSOR = "vendorNum";
export const ACTION_CHANGE_LOG_ACCESSOR = "action";
export const CHANGE_TYPE_CHANGE_LOG_ACCESSOR = "changeType";

interface DefaultColumnFilterProps {
  renderFilter?: any;
  // header?: CustomHeader | undefined;
  customFilterDisabled?: boolean;
  column: any;
  onSearch?: (selected: string) => void;
}

function ColumnSearch({
  renderFilter,
  customFilterDisabled = false,
  column,
  onSearch,
}: DefaultColumnFilterProps) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const [searchValue, setSearchValue] = useState("");

  const delayedSearchValue = useDebounce(searchValue, 500);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // const isNewOrAllSkuTable = [TableListTypeEnum.NewSku, TableListTypeEnum.AllSku]?.includes(
  //   tableType as TableListType,
  // );

  // console.log({ selected, id: column?.id, isCustomObjectKey, currentOptions, filterOptions });

  const handleClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    // setSelected([]);

    if (onSearch) onSearch("");
  };

  const handleSubmit = () => {
    // click to set selected fro refetching

    // pass to parent to fetch api
    if (onSearch) onSearch(searchValue);

    handleClose();
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setSearchValue(value);
  };

  // useEffect(() => {
  //   if (isReset) {
  //     setSelected([]);
  //   } else {
  //     setSelected(filterValue || []);
  //   }
  // }, [filterValue, isReset]);

  useEffect(() => {
    if (column?.cancelFilter !== undefined) {
      setAnchorEl(null);
    }
  }, [column?.cancelFilter]);

  return (
    <Box display="flex" alignItems="center" className="wrapperFilter">
      <BasicTooltip title={`Filter ${column?.Header ? column?.Header : "list"}`}>
        <IconButton size="small" onClick={handleClick}>
          <SearchIcon />
          {/* <FilterIconWrapper 
          active={!hasNoSelected}
          >
          </FilterIconWrapper> */}
        </IconButton>
      </BasicTooltip>

      <CustomPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {renderFilter || (
          <>
            <WrapperBox>
              <TextField
                fullWidth
                placeholder="Search"
                name="search"
                // value={delayedSearchValue}
                onChange={handleSearchChange}
              />
            </WrapperBox>

            <WrapperBox>
              <BasicButton
                sx={{ marginBottom: "8px" }}
                fullWidth
                variant="contained"
                onClick={handleSubmit}
              >
                Search
              </BasicButton>

              <BasicButton
                fullWidth
                variant="outlined"
                onClick={handleReset}
                // disabled={hasNoSelected}
              >
                Reset
              </BasicButton>
            </WrapperBox>
          </>
        )}
      </CustomPopover>
    </Box>
  );
}

export default memo(ColumnSearch);
