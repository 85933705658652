import { styled } from "@mui/material/styles";
import React, { useState } from "react";

import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { Button, IconButton } from "@mui/material";
import { BasicButtonProps, Text } from "components/core";

const StyledMenuItem = styled(MenuItem)({
  fontSize: "0.875rem",
});

const TextTransformButton = styled(Button)({
  textTransform: "none",
});

export interface MenuButtonOption {
  info?: string;
  label: string | React.ReactElement | React.ReactNode;
  onClick: (index?: number) => void;
  onRemove?: (index?: number) => void;
  disabled?: boolean;
  active?: boolean;
  color?: string;
  divider?: boolean;
}

export interface MenuButtonProps extends BasicButtonProps {
  disableMenuListPadding?: boolean;
  iconButtonEnabled?: boolean;
  colorEnabled?: boolean;
  iconDisabled?: boolean;
  removeIconEnabled?: boolean;
  icon?: React.ReactElement | null;
  label?: string | React.ReactElement;
  noOptionLabel?: string | React.ReactElement;
  menuList: MenuButtonOption[];
  onRemoveSelected?: () => void;
}

export default function MenuButton({
  disableMenuListPadding = false,
  iconButtonEnabled = false,
  colorEnabled = false,
  iconDisabled = false,
  removeIconEnabled = false,
  icon,
  label,
  noOptionLabel = "No Option",
  menuList = [],
  onRemoveSelected,
  ...rest
}: MenuButtonProps) {
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const handleClick = (event: any) => {
    event.stopPropagation();

    if (onRemoveSelected) {
      onRemoveSelected();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {iconButtonEnabled ? (
        <IconButton {...rest} id="menu-button" onClick={handleClick}>
          {icon}
        </IconButton>
      ) : (
        <TextTransformButton {...rest} id="menu-button" onClick={handleClick}>
          {label || null}

          {!iconDisabled && (icon || <ArrowDropDownRoundedIcon sx={{ marginRight: "-4px" }} />)}
        </TextTransformButton>
      )}

      <Menu
        sx={{
          "ul.MuiList-root": {
            padding: disableMenuListPadding || colorEnabled ? 0 : "8px 0",
            maxWidth: "300px",
          },
        }}
        id="menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClick={(event) => event.stopPropagation()}
        onClose={handleClose}
      >
        {menuList?.length ? (
          menuList?.map((item, index) => (
            <StyledMenuItem
              sx={(theme) => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: item?.active ? "rgba(0, 0, 0, 0.04)" : "initial",
                borderLeft: colorEnabled && item?.color ? `4px solid ${item?.color}` : "initial",
                borderBottom: item?.divider
                  ? `0.5px solid ${theme.palette.primary.light}`
                  : "unset",
              })}
              data-cy={item.label}
              disabled={item?.disabled}
              key={index}
              onClick={(event) => {
                if (item?.active) return;

                event.stopPropagation();

                if (item?.onClick) {
                  item?.onClick(index);
                  handleClose();
                }
              }}
            >
              <Text
                sx={{
                  whiteSpace: "initial",
                }}
                color="black"
                bold={item?.active}
              >
                {item?.label}
              </Text>

              {removeIconEnabled && (
                <IconButton
                  sx={{ marginLeft: "16px" }}
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();

                    if (item?.onRemove) {
                      item?.onRemove(index);
                      handleClose();
                    }
                  }}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              )}
            </StyledMenuItem>
          ))
        ) : (
          <StyledMenuItem disabled>
            <Text>{noOptionLabel}</Text>
          </StyledMenuItem>
        )}
      </Menu>
    </>
  );
}
