import { AxiosRequestConfig } from "axios";
import useAxios from "axios-hooks";
import { useEffect, useMemo, useState } from "react";

import { useErrorAlert, useSuccessAlert, useUserRole } from "hooks";

import { TableListType } from "components/core";
import API from "constants/api";
import { BUYER, LAB, SUPPLIER } from "constants/global";
import {
  CapFormValues,
  CapTestReportApproval,
  CapViewInfo,
  CustomHeader,
  EditTaskGrouping,
  TaskReport,
  TaskReportApproveData,
  TaskRequirement,
  TaskSkuGrouping,
  TestReportResultApproval,
} from "types/task";
import { TestMatrix } from "types/test-matrix";
import { HistoryLog } from "../components/Log";

function useFetchTask<T>({
  method = "GET",
  api = API.SKU,
  id = "",
  url = "",
  successAlertEnabled = false,
  successMessage = "updated successfully.",
}: {
  method?: AxiosRequestConfig["method"];
  api?: API;
  id?: string;
  url?: string;
  successAlertEnabled?: boolean;
  successMessage?: string;
}) {
  const { setError, setAppError } = useErrorAlert();
  const { setSuccess } = useSuccessAlert();

  const { isBuyer, isSupplier, isLab } = useUserRole();

  const [data, setData] = useState<T>();

  const PATH = isBuyer ? `/${BUYER}${api}` : isSupplier ? `/${SUPPLIER}${api}` : `/${LAB}${api}`;

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: !id && !url ? PATH : `${PATH}/${id}${url}`,
      method,
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    if (fetchData?.code !== 200) {
      setAppError({
        code: fetchData?.code,
        message: fetchData?.message,
      });
    }

    if (successAlertEnabled && fetchData?.code === 200) setSuccess(successMessage);

    if (fetchData?.data) setData(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data: data as T,
      isLoading,
      refetch,
      error,
    }),
    [id, url, data, isLoading],
  );
}

function useUpdateTask<Req, Res = { id?: string }>({
  method = "POST",
  api = API.SKU,
  id = "",
  url = "",
  successAlertEnabled = false,
  successMessage = "Submitted successfully.",
}: {
  method?: AxiosRequestConfig["method"];
  api?: API;
  id?: string;
  url?: string;
  successAlertEnabled?: boolean;
  successMessage?: string;
}) {
  const { setError, setAppError } = useErrorAlert();
  const { setSuccess } = useSuccessAlert();

  const { isBuyer, isSupplier } = useUserRole();

  const [data, setData] = useState<Res>();

  const PATH = isBuyer ? `/${BUYER}${api}` : isSupplier ? `/${SUPPLIER}${api}` : `/${LAB}${api}`;

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: !url ? PATH : !id ? `${PATH}/${url}` : `${PATH}/${id}${url}`,
      method,
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    if (fetchData?.code !== 200) {
      setAppError({
        code: fetchData?.code,
        message: fetchData?.message,
      });
    }

    if (successAlertEnabled && fetchData?.code === 200) setSuccess(successMessage);

    if (fetchData?.data) setData(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data,
      isLoading,
      refetch: (payload: Req) => refetch({ data: payload }),
      error,
    }),
    [api, id, url, successAlertEnabled, successMessage, data, isLoading],
  );
}

function useFetchSubmission<Req, Res = { id?: string }>({
  method = "POST",
  id = "",
  url = "",
  successAlertEnabled = false,
  successMessage = "Submitted successfully.",
}: {
  method?: AxiosRequestConfig["method"];
  id?: string;
  url?: string;
  successAlertEnabled?: boolean;
  successMessage?: string;
}) {
  const { setError, setAppError } = useErrorAlert();
  const { setSuccess } = useSuccessAlert();

  const { isBuyer, isSupplier } = useUserRole();

  const [data, setData] = useState<Res>();

  const PATH = isBuyer ? `/${BUYER}` : isSupplier ? `/${SUPPLIER}` : `/${LAB}`;

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: !id && !url ? PATH : `${PATH}/${id}${url}`,
      method,
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    if (fetchData?.code !== 200) {
      setAppError({
        code: fetchData?.code,
        message: fetchData?.message,
      });
    }

    if (successAlertEnabled && fetchData?.code === 200) setSuccess(successMessage);

    if (fetchData?.data) setData(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data,
      isLoading,
      refetch: (payload: Req) => refetch({ data: payload }),
      error,
    }),
    [id, url, successAlertEnabled, successMessage, data, isLoading],
  );
}

export function useTaskRequirements<T = TaskRequirement[]>(
  skuId: string,
  taskRequirementId: string,
) {
  return useFetchTask<T>({ id: skuId, url: `/task_requirements/${taskRequirementId}` });
}

export function useTaskReports<T = TaskReport>(skuId: string, taskId: string) {
  return useFetchTask<T>({ id: skuId, url: `/tasks/${taskId}/report_and_result` });
}

export function useTaskTestMatrix<T = TestMatrix[]>(skuId: string, taskId: string) {
  return useFetchTask<T>({ id: skuId, url: `/tasks/${taskId}/test_matrix` });
}

export function useTaskSkuGroupings<T = TaskSkuGrouping[]>(skuId: string, taskId: string) {
  return useFetchTask<T>({ id: skuId, url: `/tasks/${taskId}/sku_grouping` });
}

export function useTaskLog<T = HistoryLog[]>(skuId: string, taskId: string) {
  return useFetchTask<T>({ id: skuId, url: `/tasks/${taskId}/history` });
}

export function useEditTasksSkuGroupings<T = EditTaskGrouping>(skuId: string, taskId: string) {
  return useFetchTask<T>({ id: skuId, url: `/tasks/${taskId}/edit_sku_grouping` });
}

export function useDeleteTask<T = { comment?: string }>(skuId: string, taskId: string) {
  return useUpdateTask<T>({
    method: "DELETE",
    id: skuId,
    url: `/tasks/${taskId}`,
    successAlertEnabled: true,
    successMessage: "Task deleted",
  });
}

export function useBulkDeleteTask<T = { deleteReason?: string }>() {
  return useUpdateTask<T>({
    method: "DELETE",
    url: "tasks",
    successAlertEnabled: true,
    successMessage: "Tasks deleted",
  });
}

export function useTaskApproveReportData<T = TaskReportApproveData>(skuId: string, taskId: string) {
  return useFetchTask<T>({ id: skuId, url: `/tasks/${taskId}/approve_report` });
}

export function useApproveCapReport<T = CapTestReportApproval[]>(skuId: string, taskId: string) {
  return useUpdateTask<T>({
    method: "POST",
    id: skuId,
    url: `/tasks/${taskId}/approve_cap`,
    successAlertEnabled: true,
    successMessage: "CAP Report approved",
  });
}

export function useRejectCapReport<T = { capRejectReason: string }>(skuId: string, taskId: string) {
  return useUpdateTask<T>({
    method: "POST",
    id: skuId,
    url: `/tasks/${taskId}/reject_cap`,
    successAlertEnabled: true,
    successMessage: "CAP Report rejected",
  });
}

export function useCapInfo<T = CapViewInfo>(skuId: string, taskId: string) {
  return useFetchTask<T>({ id: skuId, url: `/tasks/${taskId}/cap` });
}

export function useSubmitCap<T = CapFormValues>(skuId: string, taskId: string, isEdit?: boolean) {
  return useUpdateTask<T>({
    method: isEdit ? "PUT" : "POST",
    id: skuId,
    url: `/tasks/${taskId}/cap`,
    successAlertEnabled: true,
    successMessage: "CAP submitted",
  });
}

export function useApproveTaskReport<T = TestReportResultApproval>(skuId: string, taskId: string) {
  return useUpdateTask<T>({
    id: skuId,
    url: `/tasks/${taskId}/approve_report`,
    successAlertEnabled: true,
    successMessage: "Test Report approved",
  });
}

export function useTaskHeaderOptions<R = CustomHeader>(type: TableListType) {
  const isSubmissions = type === "submissions";

  return useUpdateTask<any, R>({
    method: "POST",
    api: isSubmissions ? API.SUBMISSIONS : API.SKU,
    url: isSubmissions ? "filter_options" : `${type}/filter_options`,
  });
}

export function useTaskStatusFilters<R = { [key: string]: number }>(type: TableListType) {
  const isSubmissions = type === "submissions";

  return useUpdateTask<any, R>({
    method: "POST",
    api: isSubmissions ? API.SUBMISSIONS : API.SKU,
    url: isSubmissions ? "more_filter_options" : `${type}/more_filter_options`,
  });
}

export function useResumeTask<T>(skuId: string, taskId: string) {
  return useUpdateTask<T>({
    method: "PUT",
    id: skuId,
    url: `/tasks/${taskId}/resume_to_complete`,
    successAlertEnabled: true,
    successMessage: "Resume Success",
  });
}
