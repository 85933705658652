import React from "react";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";

export interface BasicButtonProps extends LoadingButtonProps {
  loading?: LoadingButtonProps["loading"];
  variant?: LoadingButtonProps["variant"];
}

export default function BasicButton({
  loading = false,
  variant = "outlined",
  children,
  ...rest
}: BasicButtonProps) {
  return (
    <LoadingButton loading={loading} variant={variant} {...rest}>
      {children}
    </LoadingButton>
  );
}
