/* eslint-disable no-nested-ternary */
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Virtuoso } from "react-virtuoso";

import {
  Box,
  Checkbox,
  IconButton,
  ListItemText,
  MenuItem,
  Popover,
  TextField,
  Theme,
} from "@mui/material";

import FilterAltIcon from "@mui/icons-material/FilterAlt";

import { BasicButton } from "components/core/Button";
import { BasicSelectOption } from "components/core/Input";
import { BasicTooltip } from "components/core/Tooltip";
import { CHANGE_REQUEST_STATUS } from "constants/change-request";
import {
  BOM_TYPE_OPTIONS,
  CONCEPT_OPTIONS,
  COUNTRY_CODE_OPTIONS,
  DC_CODE_OPTIONS,
  SKU_STATUS_OPTIONS,
  TASK_STATUS,
  ACTION_CHANGE_LOG_OPTIONS,
  CHANGE_TYPE_CHANGE_LOG_OPTIONS,
} from "constants/item";

import { DEPT_KEY, VENDOR_KEY } from "constants/task";
import { useDebounce } from "hooks";
import { CustomHeader } from "types/task";
import { textOrUnknown } from "utils";
import {
  getArrayDisplayValue,
  getArrayIndexOfValue,
  getCurrentHeaderOptions,
  getDisplayValue,
  getObjectDisplayValue,
} from "utils/table";

import { TableListType } from ".";

const FilterIconWrapper = styled(Box, {
  shouldForwardProp: (props) => props !== "active",
})<{
  active: boolean;
}>(({ theme, active }) => ({
  svg: {
    color: active ? theme.palette.primary.main : "#808080",
    width: 23,
    height: 23,
  },
}));

const WrapperBox = styled(Box)({
  padding: "8px",
});

const CustomPopover = styled(Popover)({
  ".MuiPaper-root": {
    width: "320px",
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  taskFilter: {
    "& .multiselect-container": {
      height: 400,
    },
    "& .optionContainer": {
      height: 355,
    },
  },
  filter: {
    color: theme.palette.primary.light,
    width: 23,
    height: 23,
  },
  selected: {
    color: theme.palette.primary.main,
  },
}));

export const EXPORT_MARKET_ACCESSOR = "exportMarket";
export const COUNTRY_CODE_LIST_ACCESSOR = "countryCodeList";
export const DC_LIST_ACCESSOR = "dcList";
export const BOM_ACCESSOR = "bom";
export const SKU_STATUS_ACCESSOR = "skuStatus";
export const STATUS_ACCESSOR = "status";
export const CONCEPT_ACCESSOR = "concept";
export const DEPT_ACCESSOR = "deptNum";
export const VENDOR_ACCESSOR = "vendorNum";
export const ACTION_CHANGE_LOG_ACCESSOR = "action";
export const CHANGE_TYPE_CHANGE_LOG_ACCESSOR = "changeType";

interface DefaultColumnFilterProps {
  renderFilter?: any;
  header?: CustomHeader | undefined;
  customFilterDisabled?: boolean;
  tableType?: TableListType;
  isReset?: boolean;
  column: any;
  onFilter?: (selected: string[]) => void;
}

function DefaultColumnFilter({
  renderFilter,
  header,
  customFilterDisabled = false,
  column,
  isReset = false,
  tableType,
  onFilter,
}: DefaultColumnFilterProps) {
  const classes = useStyles();

  const { preFilteredRows, setFilter, filterValue } = column;

  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState<any[]>([]);

  const [searchValue, setSearchValue] = useState("");

  const delayedSearchValue = useDebounce(searchValue, 500);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // const isNewOrAllSkuTable = [TableListTypeEnum.NewSku, TableListTypeEnum.AllSku]?.includes(
  //   tableType as TableListType,
  // );

  const isDeptNumColumnKey = column?.id === DEPT_ACCESSOR;
  const isVendorNumColumnKey = column?.id === VENDOR_ACCESSOR;

  const currentHeaderKey = isDeptNumColumnKey ? DEPT_KEY : VENDOR_KEY;

  // const isCustomObjectKey = isNewOrAllSkuTable && (isDeptNumColumnKey || isVendorNumColumnKey);
  const isCustomObjectKey = isDeptNumColumnKey || isVendorNumColumnKey;

  const DeptOrVendorOptions =
    (header &&
      (header as CustomHeader)[currentHeaderKey]?.map((item) => {
        const objectOption = {
          label: `${item?.name} (${textOrUnknown(item?.value)})` ?? "",
          value: item?.value ?? "",
        };

        return objectOption;
      })) ||
    [];

  const defaultOptions = getCurrentHeaderOptions(header, column?.id as string)?.map((item) => ({
    label: item,
    value: item,
  }));

  const currentOptions = isCustomObjectKey ? DeptOrVendorOptions : defaultOptions;

  const hasNoSelected = !selected?.length;

  const taskStatusOptions = Object.entries(TASK_STATUS)?.map((item) => {
    return {
      label: item?.[1],
      value: item?.[0],
    };
  });

  const changeRequestStatusOptions = Object.entries(CHANGE_REQUEST_STATUS)?.map((item) => {
    return {
      label: item?.[1],
      value: item?.[0],
    };
  });

  const isChangeRequestPage = window.location.href?.includes("change-requests");
  const isChangeLogPage = window.location.href?.includes("change-log");

  const getCustomOptionLabel = (item: string) => {
    switch (column?.id as string) {
      case STATUS_ACCESSOR:
        return getDisplayValue(
          isChangeRequestPage ? changeRequestStatusOptions : taskStatusOptions,
          item,
          "label",
        );
      case SKU_STATUS_ACCESSOR:
        return getDisplayValue(SKU_STATUS_OPTIONS, item, "label");
      case CONCEPT_ACCESSOR:
        return getDisplayValue(CONCEPT_OPTIONS, item, "label");
      case BOM_ACCESSOR:
        return getDisplayValue(BOM_TYPE_OPTIONS, item, "label");
      case COUNTRY_CODE_LIST_ACCESSOR:
        return getArrayDisplayValue(COUNTRY_CODE_OPTIONS, item, "label");
      case DC_LIST_ACCESSOR:
        return getArrayIndexOfValue(DC_CODE_OPTIONS, item, "label");
      case "usTaskStatus":
      case "ukTaskStatus":
      case "euTaskStatus":
      case "qcTaskStatus":
      case "usStatus":
      case "ukStatus":
      case "euStatus":
      case "qcStatus":
        return getObjectDisplayValue(TASK_STATUS, item);
      case ACTION_CHANGE_LOG_ACCESSOR:
        return isChangeLogPage ? getDisplayValue(ACTION_CHANGE_LOG_OPTIONS, item, "label") : item;
      case CHANGE_TYPE_CHANGE_LOG_ACCESSOR:
        return isChangeLogPage
          ? getDisplayValue(CHANGE_TYPE_CHANGE_LOG_OPTIONS, item, "label")
          : item;
      default:
        return item;
    }
  };

  const filterOptions: BasicSelectOption[] = currentOptions?.length
    ? currentOptions?.map((item) => {
        return {
          label: getCustomOptionLabel(item?.label) || item?.label,
          value: item?.value,
        };
      })
    : preFilteredRows?.reduce((acc: BasicSelectOption[], cur: any) => {
        // check if exists & opt out duplicates
        if (
          column?.id &&
          cur?.values[column?.id] &&
          acc.findIndex((item: any) => item?.value === cur?.values[column?.id]) === -1
        )
          acc.push({
            label: cur?.values[column?.id],
            value: cur?.values[column?.id],
          });

        return acc;
      }, []);

  const isChecked = useCallback(
    (targetValue: string): boolean => {
      return !!selected?.filter((item) => item === targetValue)?.length;
    },
    [selected, currentOptions],
  );

  // console.log({ selected, id: column?.id, isCustomObjectKey, currentOptions, filterOptions });

  const handleCheckbox = (selectedValue: any) => (event: any) => {
    const updated = isChecked(selectedValue)
      ? selected.filter((selectedItem) => selectedItem !== selectedValue)
      : [...selected, selectedValue];

    setSelected(updated);
  };

  const handleClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    setSelected([]);

    if (onFilter) onFilter([]);

    if (customFilterDisabled) setFilter([]);
  };

  const handleSubmit = () => {
    // click to set selected fro refetching

    // pass to parent to fetch api
    if (onFilter) onFilter(selected);

    if (customFilterDisabled) setFilter(selected);

    handleClose();
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setSearchValue(value);
  };

  useEffect(() => {
    if (isReset) {
      setSelected([]);
    } else {
      setSelected(filterValue || []);
    }
  }, [filterValue, isReset]);

  useEffect(() => {
    if (column?.cancelFilter !== undefined) {
      setAnchorEl(null);
    }
  }, [column?.cancelFilter]);

  const filtered = filterOptions?.filter((option) => {
    const lowerCaseOptionLabel = option?.label?.toString()?.toLocaleLowerCase() ?? "";
    const lowerCaseOptionValue = option?.value?.toString()?.toLocaleLowerCase() ?? "";

    const lowerCaseSearchVal = delayedSearchValue?.toLowerCase() || "";

    return (
      lowerCaseOptionLabel?.includes(lowerCaseSearchVal) ||
      lowerCaseOptionValue?.includes(lowerCaseSearchVal)
    );
  });

  return (
    <Box display="flex" alignItems="center" className="wrapperFilter">
      <BasicTooltip title={`Filter ${column?.Header ? column?.Header : "list"}`}>
        <IconButton size="small" onClick={handleClick}>
          <FilterIconWrapper active={!hasNoSelected}>
            <FilterAltIcon />
          </FilterIconWrapper>
        </IconButton>
      </BasicTooltip>

      <CustomPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {renderFilter || (
          <>
            <WrapperBox>
              <TextField
                fullWidth
                placeholder="Search filter option"
                name="search"
                // value={delayedSearchValue}
                onChange={handleSearchChange}
              />
            </WrapperBox>

            <Box
              sx={{
                maxHeight: "300px",
                height: "100%",
                overflow: "auto",
              }}
            >
              {open && filtered && filtered.length > 0 ? (
                <Virtuoso
                  style={{ height: "290px" }}
                  totalCount={filtered.length}
                  data={filtered}
                  overscan={200}
                  itemContent={(index, data) => (
                    <MenuItem
                      key={data.value}
                      // sx={{ paddingLeft: "8px" }}
                      value={data?.value}
                      onClick={handleCheckbox(data?.value)}
                    >
                      <Checkbox checked={isChecked(data?.value)} />

                      <ListItemText
                        sx={{ whiteSpace: "normal", wordBreak: "break-all" }}
                        primary={data?.label}
                      />
                    </MenuItem>
                  )}
                />
              ) : (
                <WrapperBox>
                  <ListItemText primary="No options" />
                </WrapperBox>
              )}
            </Box>

            <WrapperBox>
              <BasicButton
                sx={{ marginBottom: "8px" }}
                fullWidth
                variant="contained"
                onClick={handleSubmit}
              >
                Filter
              </BasicButton>

              <BasicButton
                fullWidth
                variant="outlined"
                onClick={handleReset}
                disabled={hasNoSelected}
              >
                Reset
              </BasicButton>
            </WrapperBox>
          </>
        )}
      </CustomPopover>
    </Box>
  );
}

export default memo(DefaultColumnFilter);
